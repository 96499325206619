import React from 'react';
import {DateTime} from 'luxon';
import NumberFormat from 'react-number-format';
import {InstalmentPlanDetailFragment} from 'lib/graphql/API';
import {classNames} from 'lib/styles';
import {formatToDollars, toTitleCase} from 'payble-shared';

type InstalmentTableProps = {
  instalmentPlan: InstalmentPlanDetailFragment;
  // showAll
  // showFuture
  // showUntilDate
};

export const InstalmentTable: React.FC<InstalmentTableProps> = ({
  instalmentPlan,
}) => {
  const rows = instalmentPlan.instalments.map(x => {
    let actionDate: DateTime | null = null;
    switch (x.status) {
      case 'skipped':
        actionDate = DateTime.fromISO(x.skippedAt ?? '');
        break;
      case 'cancelled':
        actionDate = DateTime.fromISO(x.cancelledAt ?? '');
        break;
      case 'paid':
        actionDate = DateTime.fromISO(x.paidAt ?? '');
        break;
      case 'missed':
        actionDate = DateTime.fromISO(x.missedAt ?? '');
        break;
    }

    return (
      <div key={x.instalmentId} className="py-2 flex text-xs sm:text-sm">
        <div className="basis-3/12 px-2">{x.dueAt.toFormat('dd MMM yyyy')}</div>
        <div className="basis-2/12 text-right px-2">
          <NumberFormat
            value={formatToDollars(x.amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </div>
        <div className="basis-4/12 justify-center text-center px-2 pl-6">
          <div
            className={classNames(
              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs sm:text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
              x.status === 'paid' ? 'bg-green-100 text-green-800' : '',
              x.status === 'scheduled' ? 'bg-gray-100 text-gray-800' : '',
              x.status === 'processing' ? 'bg-gray-100 text-gray-800' : '',
              x.status === 'missed' ? 'bg-red-100 text-red-800' : '',
              x.status === 'skipped' ? 'bg-orange-100 text-orange-800' : '',
              x.status === 'cancelled' ? 'bg-yellow-100 text-yellow-800' : ''
            )}
          >
            {toTitleCase(x.status as string)}
          </div>
        </div>
        <div className="basis-3/12 px-2 text-right">
          {actionDate !== null ? actionDate.toFormat('dd MMM yyyy') : ''}
        </div>
      </div>
    );
  });

  return (
    <div className="divide-y divide-gray-50 mt-4 md:w-[464px]">
      <div className="py-2 flex text-xs sm:text-sm font-semibold">
        <div className="basis-3/12 px-2">Due Date</div>
        <div className="basis-2/12 px-2 text-right">Amount</div>
        <div className="basis-4/12 px-2 text-center pl-6">Status</div>
        <div className="basis-3/12 px-2 text-right">Actioned</div>
      </div>
      {rows}
    </div>
  );
};
